import * as React from "react"
import Layout from "../components/layout"
import GalleryMontagne from "../components/GalleryMontagne"

const GalleryPageMontagne = () => (
  <Layout>
    <GalleryMontagne />
  </Layout>
)

export default GalleryPageMontagne